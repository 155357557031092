export default class dolarAPI {
    async fetchAmbito() {
        let response = await fetch('json/ambito.json').then((r) => r.json());
        return response;
    }
    async fetchBanks() {
        let response = await fetch('json/bancos.json').then((r) => r.json());
        return response;
    }
    async fetchTS() {
        let response = await fetch('json/timeseries.json').then((r) => r.json());
        return response;
    }
    async fetchLastUpdate() {
        let response = await fetch('json/last_update.json').then((r) => r.json());
        return response;
    }
};