import React from "react";
import Card from "./components/card/card";
import dolarAPI from "./services/dolarAPI";
import { ProgressBar } from "react-fetch-progressbar";
import "./App.css";
import Banco_Nacion from "./assets/images/banco_nacion.png";
import Banco_Hipotecario from "./assets/images/banco_hipotecario.png";
import Banco_Chaco from "./assets/images/banco_chaco.png";
import Banco_Pampa from "./assets/images/banco_de_la_pampa.png";
import Banco_Frances from "./assets/images/banco_frances.png";
import Banco_Roela from "./assets/images/Banco-Roela.png";
import Banco_Cordoba from "./assets/images/BANCOR.png";
import Banco_Galicia from "./assets/images/Banco_Galicia.png";
import banco_transatlantica from "./assets/images/banco_transatlantica.png";
import Banco_Santander from "./assets/images/Banco_Santander.png";
import Banco_Ciudad from "./assets/images/Banco_Ciudad.png";
import Banco_Supervielle from "./assets/images/Banco_Supervielle.png";
import bancopatagonia from "./assets/images/bancopatagonia.png";
import banco_comafi from "./assets/images/banco_comafi.png";
import banco_roca from "./assets/images/banco_roca.png";
import sbt_cambio from "./assets/images/sbt_cambio.png";
import Banco_Provincia from "./assets/images/Banco_Provincia.png";
import banco_icbc from "./assets/images/banco_icbc.png";
import rebanking from "./assets/images/rebanking.png";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      timeseries: {},
      ambitoData: [],
      banksData: [],
      lastUpdate: "",
    };

    this.dolarAPI = new dolarAPI();
  }

  componentDidMount() {
    this.dolarAPI.fetchAmbito().then((response) => {
      let data = response;
      if (data) {
        this.setState({ ambitoData: data });
      } else {
        this.setState({ ambitoData: [] });
      }
    });

    this.setState({ banksData: [] });

    this.dolarAPI
      .fetchLastUpdate()
      .then((response) => this.setState({ lastUpdate: response.last_update }));

  }

  renderCards() {
    const { ambitoData } = this.state;
    let cards = [];
    ambitoData.forEach((dolar, i) => {
      cards.push(
        <div className="item" key={"dolar-" + i}>
          <Card
            title={dolar.nombre}
            buyer={dolar.compra}
            seller={dolar.venta}
          />
        </div>
      );
    });
    return cards;
  }

  resolveImage(bankName) {
    let imageArrange = {
      "Banco Nación": Banco_Nacion,
      "Banco Hipotecario": Banco_Hipotecario,
      "Nuevo Banco del Chaco": Banco_Chaco,
      "Banco de la Pampa": Banco_Pampa,
      "Banco BBVA": Banco_Frances,
      "Banco Roela": Banco_Roela,
      "Banco de Córdoba": Banco_Cordoba,
      "Banco Galicia": Banco_Galicia,
      "Transatlantica S.A.": banco_transatlantica,
      "Banco Santander": Banco_Santander,
      "Banco de la Ciudad de Buenos Aires": Banco_Ciudad,
      "Banco Supervielle": Banco_Supervielle,
      "Banco Patagonia": bancopatagonia,
      "Banco Comafi": banco_comafi,
      "Cambios Roca S.A.": banco_roca,
      "SBT Cambio S.A.": sbt_cambio,
      "Banco de la Provincia de Buenos Aires": Banco_Provincia,
      "Industrial and Commercial Bank of China ": banco_icbc,
      Rebanking: rebanking,
    };
    if (!imageArrange[bankName]) return;
    return (
      <img className="bank-image" src={imageArrange[bankName]} alt={bankName} />
    );
  }

  render() {
    return (
      <div className="layout">
        <ProgressBar style={{ backgroundColor: "#b5f595", height: "3px" }} />
        <h1>
          <a href="/">
            <img className="logo" src="favicon.png" alt="logo" />
            Casamonedas.com

            <div className="last-update-text">
              <span>Ultima actualización: {this.state.lastUpdate}</span>
            </div>
          </a>
        </h1>
        <div className="App">
          <div className="wrap-container">
            {this.renderCards()}
          </div>
          {this.state.timeseries.categories && (
            <div className="ts-wrapper">
              <HighchartsReact
                highcharts={Highcharts}
                options={this.state.timeseries}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default App;
