import React from 'react';
import './card.css'

class Card extends React.Component {
    render() {
      const { title, last_update, seller, buyer, coti } = this.props
      return (
        <div className="card-container">
          { !coti &&
            <div>
              <div className="card-title">{title}</div>
              <div className="card-last-update">{last_update}</div>
              <div className="card-value">
                <span className="indicator">Compra: &nbsp;</span>${buyer} -
                <span className="indicator">Venta: &nbsp;</span>${seller}
              </div>
            </div>
          }

          { coti &&
              <div>
                <div>
                  <div className="card-title">{title}</div>
                </div>
                <div className="card-value">
                  <span className="indicator">{coti}</span>
                </div>
              </div>
          }
        </div>
      )
    }
}

export default Card;